import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-980c040e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "logo" }
const _hoisted_3 = { class: "content d-padding-left-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_image = _resolveComponent("n-image")!
  const _component_n_ellipsis = _resolveComponent("n-ellipsis")!

  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.item.url,
    target: "_blank",
    class: "d-flex d-col-top item"
  }, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_n_image, {
        height: "100",
        src: _ctx.item.icon,
        "img-props": {'style': 'max-width: 100%;border-radius: 6px;'},
        "preview-disabled": true
      }, null, 8, ["src"])
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.item.name), 1),
      _createVNode(_component_n_ellipsis, {
        style: {"max-width":"350px","word-break":"break-all"},
        "line-clamp": "2"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.item.description), 1)
        ]),
        _: 1
      })
    ])
  ], 8, _hoisted_1))
}